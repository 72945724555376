<template>
  <div>
              
    <b-container class="mt-5">
      <b-row>
        <b-col cols="12" class="mb-5">
          <h1 class="display-4  super-strong">Igualdad de Género </h1>
          <hr class="line" />
        </b-col>
      </b-row>

      <b-row>

        <b-col cols="8">
          
          <div v-for="post in postsCategory" :key="post.id" class="mb-5 pb-5">
            <div class="image-cover">
              <b-img v-bind:src="`${post.image_home}`" fluid-grow :alt="`${post.title}`" class="shadow box-5"></b-img>
            </div>

            <h2 class="mt-4">
              <b-link :to="`${post.category_id.slug}/${post.slug}`">
                {{ post.title }}
              </b-link>
            </h2>

            <div class="data-text">
              <b-button variant="danger" class="rojo light text-uppercase">
                <i class="fas fa-tag"></i>&nbsp; {{ post.category_id.name }} 
              </b-button>
              &nbsp;
              <b-button variant="secondary" class="light text-uppercase">
                <i class="fas fa-calendar-alt"></i>&nbsp; {{ post.published_at | moment('DD/MMM/YYYY h:mm a') }} 
              </b-button>
              &nbsp;

              <b-button variant="primary" v-b-tooltip.hover title="Comparte en Facebook">
                <i class="fab fa-facebook"></i>
              </b-button>
              &nbsp;
              <b-button variant="info" v-b-tooltip.hover title="Comparte en Twitter">
                <i class="fab fa-twitter"></i>
              </b-button>
              &nbsp;
              <b-button v-b-tooltip.hover title="Comparte en Whatsapp" class="green accent-4 border-wa">
                <i class="fab fa-whatsapp"></i>
              </b-button>

              <hr class="line">

            </div>


            <p class="lead">{{ post.summary }}</p>


            <b-button
              style="float:right"
              class="rojo white-text"
              :to="{ name: 'PageGenero', params: { canonical: post.canonical }}">
              <i class="fas fa-external-link-square-alt"></i>
              Leer más...
            </b-button>



          </div>

        </b-col>
          

        <b-col cols="4">
          <h3>SIDEBAR</h3>
        </b-col>

      </b-row>
    </b-container>

  </div>
</template>

<script>

export default{
  name: 'IndexPosts',
  components:{
  },
  data() {
    return {

      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
    }
  },

  mounted() {
    document.title = 'Igualdad de Género  | IME';
  },

  computed:{
  },


  /*
   * Ajuste segun categoria
   * Hereda de Categoria Padre
   */
  async created() {
    await fetch(
      this.path.endpoint + '/blogs/?type_publication_id=1&_sort=published_at:desc&_limit=100&post_type_in=3&post_type_in=4&post_type_in=5&category_id.slug=genero' // <- Ajuste segun Categoria
    ).then(response => response.json())
    .then(data => {
      this.postsCategory = data;
      console.log(data);
    })
    .catch(err => {
      console.error(err);
    });
  },
  /* End Ajuste */


  methods: {
  }



}
</script>
